* {
    margin: 0;
    padding: 0;
    --light-bg-color: #5C606F;
    --card-bg-color: #1b66ce;
    --dark-bg-color: #272B3E;
    --card-header-bg-color: #363B53;
    --text-color: white;
    --helper-color: #00b0ff;
    color: var(--text-color);
    font-family: "Open Sans", Arial, serif;
}

.underline {
    text-decoration: underline;
}

h2, h3, h4, h5, h6 {
    font-weight: normal;
}

.fa {
    color: var(--helper-color);
}

.fa:hover {
    cursor: pointer;
}

header {
    background: #222;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

header h2 {
    font-weight: bold;
}

header button {
    color: #000000;
    background-color: #ffb000;
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
}

button:hover {
    cursor: pointer;
    scale: 1.1;
    transition: all .2s;
}

.container {
    display: flex;
    flex-direction: row;
}

.left-col {
    padding: 20px;
    width: 600px;
    height: calc(100vh - 40px - 82px);
    background-color: var(--dark-bg-color);
}

.main-col {
    padding: 20px;
    height: calc(100vh - 40px - 82px);
    width: 100%;
    background-color: var(--dark-bg-color);
}

@media only screen and (max-width: 1300px) {
    .container {
        flex-direction: column;
    }

    .left-col {
        height: 100%;
        width: calc(100% - 40px);
    }

    .main-col {
        width: calc(100% - 40px);
    }

    h1 {
        font-size: 20px;
    }
}

.form-element+.form-element {
    margin-top: 30px;
}

input {
    margin-top: 10px;
    height: 50px;
    width: calc(100% - 10px);
    font-size: 20px;
    padding-left: 10px;
    background-color: var(--light-bg-color);
    border: none;
    color: white;
}

.helper {
    margin: 10px 0;
    background-color: #00628FFF;
    padding: 10px;
}

.card {
    padding: 20px;
    background-color: var(--card-bg-color);
}

.card+.card {
    margin-top: 20px;
}

.cardHolder {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardHolder .card {
    width: 100%;
}

.cardHolder .card+.card {
    margin: 0 0 0 20px;
}

.result {
    font-size: 40px;
    margin-top: 10px;
    font-weight: bold;
}

.card-green {
    background-color: #038f38;
}

@media only screen and (max-width: 940px) {
    .cardHolder {
        flex-direction: column;
    }

    .cardHolder .card {
        width: calc(100% - 40px);
    }

    .cardHolder .card+.card {
        margin: 20px 0 0 0;
    }
}
